<template lang="pug">
.om-onboarding-agency-branching.mb-6.mb-md-0
  wizard-top.mb-4.mb-md-5(:show-progress="false")
  .container
    .row
      .d-none.d-lg-flex.col-lg-1
      .col-3
        om-monks.mb-4.mb-md-0(monk="keyboard-monk")
      .om-onboarding-agency-branching-container.col-9.col-lg-7.pb-5
        .om-onboarding-agency-branching-title {{ $t('onboarding.agencyBranching.title') }}
        .om-onboarding-agency-branching-subtitle {{ $t('onboarding.agencyBranching.subTitle', { businessName }) }}
        om-select-cards.w-100(v-model="relationToBusiness" :options="relationToBusinessOptions")
      .d-none.d-lg-flex.col-lg-1
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import WizardTop from '@/components/Wizard/Top.vue';
  import WizardTitle from '@/components/Wizard/Title.vue';
  import InlineSvg from 'vue-inline-svg';
  import { accountSetupRouteNames } from '@/router/routes/accountSetupRoutes';
  import { track } from '@/services/xray';
  import navigationMixin from '../accountSetup-navigation';

  export default {
    components: { WizardTop, WizardTitle, InlineSvg },
    mixins: [navigationMixin],

    data() {
      return {
        relationToBusiness: null,
      };
    },

    computed: {
      ...mapState(['account']),
      businessName() {
        return this.account.businessName;
      },
      relationToBusinessOptions() {
        const obj = this.$t('onboarding.agencyBranching.options');

        return Object.keys(obj).map((k) => ({
          icon: k,
          value: k,
          key: obj[k].title,
          details: obj[k].description.replace('{businessName}', this.businessName),
          backgroundOff: true,
          iconSize: 70,
        }));
      },
    },

    watch: {
      relationToBusiness() {
        this.onOptionClick();
      },
    },

    methods: {
      ...mapActions(['flagAccountAsAgency']),
      async onOptionClick() {
        track('onboarding-relation-to-business', { answer: this.relationToBusiness });
        if (this.relationToBusiness === 'client') {
          this.flagAccountAsAgency();
          this.$router.push({ name: accountSetupRouteNames.AGENCY_CONTACT });
          return;
        }

        await this.finish();

        this.$router.push({ name: 'dashboard' });
      },
    },
  };
</script>
<style lang="sass">
  .om-onboarding-agency-branching
    &-container
      max-width: 472px
    &-title
      font-weight: 700
      font-size: 24px
      margin-bottom: 1rem
    &-subtitle
      margin-bottom: 1rem
      font-size: 16px

  @media screen and (max-width: 1399px)
    .om-onboarding-agency-branching
      .brand-select-card-with-details
          height: 80px
</style>
